import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/service/account.service';
@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent implements OnInit {
  light_theme: Object = {
    '--main-background-color': 'white',
    '--text-color': 'black',
    '--login-form-background-color': '#f1f7fc',
    '--login-icon-color': '#c7ede0',
    '--login-text-color': '#6f7a85',
    '--footer-background-color': '#c7ede0',
    '--footer-text-color': '#4b4c4d',
    '--footer-copyright-text-color': 'rgb(136, 136, 136)',
    '--menu-active-color': '#e68c23',
    '--menu-inactive-color': '#707070',
    '--search-tab-active-color': '#000000',
    '--search-tab-inactive-color': '#999999',
    '--component-background-color': 'white',
    '--component-title-text-color': '#707070',
    '--component-title-background-color': 'rgb(199, 237, 224)',
    '--loader-color': '#0dc5c1',
    '--border-line-color': '#dee2e6',
    '--list-select-color': 'rgb(229, 251, 243)',
    '--list-hover-color': '#eefffb',
    '--nav-tab-activate-background-color': '#e9f8f3',
    '--nav-exit-color': '#707070',
    '--new-received-mail-text-color': 'white',
    '--new-received-mail-background-color': '#bbb',
    '--auto-matching-tab-background-color': '#f9f9f9',
    '--card-hover-color': 'white',
    '--logo-background-color': 'var(--main-background-color)',
    '--logo-invert': 'invert(0%)',
    '--btn-primary-color': '#65b19a',
    '--btn-primary-color-hover': '#509a84',
    '--btn-secondary-color': '#c96862',
    '--btn-secondary-color-hover': '#a55551',
    '--btn-tertiary-color': '#777777',
    '--btn-tertiary-color-hover': '#555555',
    '--btn-text-color': 'white',
    '--btn-search-section-textarea': 'rgb(255, 255, 255)',
    '--btn-search-section-textarea-hover': 'rgb(221, 221, 221)',
    '--qoala-light-pink': 'rgb(240, 128, 128)',
    '--qoala-light-blue': 'rgb(100, 149, 237)',
    '--qoala-light-green': 'rgb(103, 172, 101)',
    '--qoala-red-tag': 'rgb(200, 50, 50)',
    '--qoala-red-tag-hover': 'rgb(170, 40, 40)',
    '--qoala-blue-tag': 'rgb(30, 80, 200)',
    '--qoala-blue-tag-hover': 'rgb(20, 60, 180)',
    '--qoala-green-tag': 'rgb(30, 160, 30)',
    '--qoala-green-tag-hover': 'rgb(20, 120, 20)',
    '--quick-tag-text-color': 'white',
    '--offering-tag-text-color': 'white',
    '--offering-tag-color': '#f29f3d',
    '--registered-tag-text-color': 'white',
    '--registered-tag-color': '#777777',
    '--matching-label-text-color': 'rgb(255, 255, 255)',
    '--term-of-service-before-login-background-color': 'white',
    '--term-of-service-after-login-background-color': 'rgb(245, 245, 245)',
    '--close-button-color': 'rgb(110, 110, 110)',
    '--highlight-color': '#c7ede0',
    '--warning-color': 'rgb(247, 104, 104)',
    '--reject-text-color': 'rgb(180, 180, 180)',
    '--light-text-color': 'rgb(130, 130, 130)',
    '--thumb-up-color': '#be4943',
    '--thumb-down-color': '#004da2',
    '--search-section-background-color': '#f9f9f9',
    '--search-section-scrollbar-thumb-color': '#777777',
    '--search-section-scrollbar-track-color': '#e4e4e4'
  };

  dark_theme = {
    '--main-background-color': 'rgb(17, 17, 17)',
    '--text-color': 'rgb(211, 211, 211)',
    '--login-form-background-color': 'black',
    '--login-icon-color': 'rgb(155, 155, 155)',
    '--login-text-color': 'rgb(220, 233, 247)',

    '--footer-background-color': '#327a7a',
    '--footer-text-color': 'rgb(220, 233, 247)',
    '--footer-copyright-text-color': 'rgb(220, 233, 247)',

    '--menu-active-color': 'rgb(228, 138, 36)',
    '--menu-inactive-color': 'rgb(202, 202, 202)',
    '--nav-tab-activate-background-color': 'rgb(5, 43, 18)',
    '--nav-exit-color': 'rgb(90, 120, 100)',
    '--search-tab-active-color': 'rgb(90, 120, 100)',
    '--search-tab-inactive-color': 'rgb(105, 105, 105)',

    '--component-title-background-color': 'rgb(14, 48, 26)',
    '--component-title-text-color': 'rgb(212, 212, 212)',
    '--component-background-color': 'rgb(34, 34, 34)',
    '--auto-matching-tab-background-color': 'rgb(34, 34, 34)',
    '--card-hover-color': 'rgb(26,26,26)',
    '--loader-color': 'rgb(26, 65, 65)',

    '--list-hover-color': 'rgb(52, 65, 62)',
    '--list-select-color': 'rgb(41, 76, 64)',

    '--border-line-color': '#4b4b4b',

    '--new-received-mail-text-color': 'white',
    '--new-received-mail-background-color': '#bbb',

    '--logo-background-color': 'var(--main-background-color)',
    '--logo-invert': 'invert(70%)',

    '--btn-primary-color': 'rgb(30, 87, 69)',
    '--btn-primary-color-hover': 'rgb(40, 77, 66)',

    '--btn-secondary-color': 'rgb(95, 36, 32)',
    '--btn-secondary-color-hover': 'rgb(82, 42, 90)',

    '--btn-tertiary-color': 'rgb(61, 60, 60)',
    '--btn-tertiary-color-hover': 'rgb(47, 47, 47)',
    '--btn-text-color': 'rgb(201, 201, 201)',

    '--btn-search-section-textarea': 'rgb(44, 44, 44)',
    '--btn-search-section-textarea-hover': 'rgb(22, 22, 22)',

    '--qoala-light-pink': 'rgb(99, 22, 22)',
    '--qoala-light-blue': 'rgb(20, 46, 94)',
    '--qoala-light-green': 'rgb(27, 98, 53)',
    '--qoala-red-tag': 'rgb(170, 40, 40)',
    '--qoala-red-tag-hover': 'rgb(150, 30, 30)',
    '--qoala-blue-tag': 'rgb(20, 60, 180)',
    '--qoala-blue-tag-hover': 'rgb(10, 40, 160)',
    '--qoala-green-tag': 'rgb(20, 120, 20)',
    '--qoala-green-tag-hover': 'rgb(10, 100, 10)',
    '--quick-tag-text-color': 'rgb(201, 201, 201)',

    '--offering-tag-text-color': 'rgb(202, 202, 202)',
    '--offering-tag-color': 'rgb(112, 61, 2)',

    '--registered-tag-text-color': 'rgb(202, 202, 202)',
    '--registered-tag-color': 'rgb(77, 73, 73)',

    '--matching-label-text-color': 'rgb(22, 22, 22)',
    '--term-of-service-before-login-background-color': 'black',
    '--term-of-service-after-login-background-color': 'rgb(34, 34, 34)',
    '--close-button-color': 'rgb(214, 214, 214)',
    '--highlight-color': 'rgb(30, 87, 69)',
    '--warning-color': 'rgb(177, 78, 78)',
    '--reject-text-color': 'rgb(94, 94, 94)',
    '--light-text-color': 'rgb(130, 130, 130)',
    '--thumb-up-color': '#be4943',
    '--thumb-down-color': '#0e7bf2',

    '--search-section-background-color': 'rgb(34, 34, 34)',
    '--search-section-scrollbar-thumb-color': '#e4e4e4',
    '--search-section-scrollbar-track-color': '#777777'
  };
  constructor(public accountService: AccountService) {}
  switchTheme() {
    this.accountService.themeMode = !this.accountService.themeMode;
    this.setTheme();
  }

  setTheme() {
    if (this.accountService.themeMode) {
      for (const [key, value] of Object.entries(this.light_theme)) {
        document.documentElement.style.setProperty(key, value);
      }
      localStorage.setItem('theme', 'light');
    } else {
      for (const [key, value] of Object.entries(this.dark_theme)) {
        document.documentElement.style.setProperty(key, value);
      }
      localStorage.setItem('theme', 'dark');
    }
  }
  ngOnInit() {
    const theme_mode = localStorage.getItem('theme');
    if (theme_mode) {
      this.accountService.themeMode = theme_mode === 'light';
    } else {
      localStorage.setItem('theme', 'light');
      this.accountService.themeMode = true;
    }
    this.setTheme();
  }
}
